import * as React from "react"

import Layout from "../../components/layout/layout"
import PluginsHeroSectionNew from "../../components/sections/plugins-hero-section-new"
import PluginsPageWordPressListSectionNew from "../../components/sections/plugins-page-wordpress-list-section-new"
import Seo from "../../components/seo"


const PluginsWordPressPage = () => {
  return (
    <Layout>
      <Seo title="Best WordPress Plugins" />

      <PluginsHeroSectionNew subTitle="Best WordPress Plugins" />

      <PluginsPageWordPressListSectionNew />
    </Layout>
  )
}

export default PluginsWordPressPage
